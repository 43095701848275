/* ================= Common Card Css Start ========================= */
.common-card {
    border: 0;
    box-shadow: var(--box-shadow); 
    border-radius: 16px;
    background-color: hsl(var(--white));
    .card-header, .card-footer {
        padding: 12px 24px;
        background-color: transparent;
        border-bottom: 1px solid hsl(var(--border-color));
        @include xsm-screen {
            padding: 12px 16px;
        }
    }
    .card-header {
        background-color: hsl(var(--black));
        .title {
            color: hsl(var(--static-white));
            margin-bottom: 0;
        }
    }
    .card-body {
        padding: 24px;
        @include xsm-screen {
            padding: 16px;
        }
    }
    .card-footer {
        border-top: 1px solid hsl(var(--border-color));
        border-bottom: 0;
    }
}

  @media (min-width: 1100px) {
    .showcase-container {
        padding-left : 200px;
        padding-right: 200px;
        }
  }
  @media (max-width : 1099px){
    .showcase-container{
        padding-left: 50px;
        padding-right: 50px;
    }
  }
@media (max-width: 600px) { 
    .product-preview { 
        padding-left: 5px; 
        padding-right: 5px;
    } 
}
.showcase-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border-width: 1px;
    margin-top: 10px;
    align-content: center;
}
.card-img {
    width:300px;
    height:300px;
    border-radius: 5px;
}
.card-container{
    display: flex;
    flex-direction: column;
    margin: 20px;
    // padding: 10px;
    align-items: center;
    border-style: solid;
    border-color: whitesmoke;
    border-radius: 5px;
    align-items: center;
}
.card-title{
    width: 300px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
}

/* ================= Common Card Css End ========================= */