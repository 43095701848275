/* =========================== Banner Section Start Here ========================= */
.hero {
    position: relative;
    z-index: 1;
    padding: 132px 0;
    @include md-screen {
        padding: 80px 0;
    }
    @include sm-screen {
        padding: 60px 0;
    }
}

.hero-inner {
    &__title {
        margin-bottom: 24px;
        // font-size: clamp(2rem, -2.0093rem + 7.85vw, 4.5rem);
        @include md-screen {
            margin-bottom: 16px;
        }
    }
    &__desc {
        max-width: 572px;
        margin-bottom: clampCal(24, 48);
    }
}
.list-circle{
    list-style-type: circle;
    padding-left: 50px;
}
.list-span {
    position: relative;
    left: -12px;
}
.img-support{
    width: auto;
    height: 200px;
    border-radius: 16px;
}
.img-banner{
    height: 350px;
    border-radius: 10px;
}
.hero-thumb {
    margin-right: auto;
    position: relative;
    transform: translateX(120px);
    @include xxxl-screen {
        transform: translateX(80px);
    }
    @include xxl-screen {
        transform: translateX(0px);
    }
    display: flex;
    align-items: center;
}

/* tech list start */
.product-category-list {
    margin-top: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    
    @include lg-screen {
        margin-top: 24px;
        gap: 12px;
    }
    &__item {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: hsl(var(--white));
        flex-shrink: 0;
        @include lg-screen {
            width: 40px;
            height: 40px;
        }
    }
}
/* tech list End */

/* Statistics Start */

.statistics {
    padding: 34px 16px;
    border-radius: 16px;
    border: 1px solid hsl(var(--white));
    box-shadow: 0px 20px 30px 0px rgba(94, 53, 242, 0.3);
    display: inline-block;
    aspect-ratio: 1;
    min-width: 120px;
    top: 42%;
    left: 33%;
    position: absolute;
    &.style-two {
        right: 0px;
        border-radius: 16px;
        box-shadow: -10px 20px 30px 0px rgba(189, 190, 204, 0.5);
        left: auto;
        top: 60px;
        max-width: 120px;
    }
    &.style-three {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: none !important;
        padding: 34px 10px;
        @media (min-width: 1699px) {
            min-width: 196px;
        }
        .statistics {
            &__amount {
                margin-bottom: 8px;
            }
            &__text {
                @include xxl-screen {
                    @include font-14; 
                }
                @include xl-screen {
                    @include font-12; 
                }

            }
        }
    }
    &.animation {
        animation: upDown 20s linear infinite;
    }
    &__amount {
        margin-bottom: 0;
    }
}

/* Statistics End */
@keyframes upDown {
    0%, 100% {
        transform: translateY(0px) scale(1);
    }
    25% {
        transform: translateY(30px) scale(1.1);
    }
    50% {
        transform: translateY(0px) scale(1);
    }
    75% {
        transform: translateY(-30px) scale(1.1);
    }
}

.dotted-img {
    position: absolute;
    left: 20%;
    top: 37%;
    z-index: -1;
    animation: scaling 20s linear infinite;
    max-width: 75%;
    @include msm-screen {
        display: none;
    }
}

@keyframes scaling {
    0%, 100% {
        transform: scale(.6);
    }
    50% {
        transform: scale(1.2);
    }
}

/* =========================== Banner Section End Here ========================= */