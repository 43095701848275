/* ================================= Pagination Css Start =========================== */
.common-pagination {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 64px;
    gap: 8px;
    align-items: center;
    @include lg-screen {
        margin-top: 48px;
    }
    @include md-screen {
        margin-top: 40px;
    }
    @include sm-screen {
        margin-top: 32px;
    }
    .page-item {
        &__active {
            font-weight: bold;
            text-decoration: underline;
        }
            
        &:hover, &:focus, &:focus-visible {
            cursor: pointer;
            font-weight: bold;
            text-decoration: underline;
        }
        &:focus {
            box-shadow: none;
        }
        &:last-child {
            
            &:hover, &:focus, &:focus-visible {
                cursor: pointer;
                font-weight: bold;
            }
            
        }
    }
}
/* ================================= Pagination Css End =========================== */