/* ======================== popular Section Css Start =========================== */
.popular {
    &__button {
        margin-top: clampCal(24, 48);
    }
}

.popular-item {
    padding: 40px 16px;
    text-align: center;
    border-radius: 8px;
    background: linear-gradient(117deg, #FFF2F9 14.01%, #F2EEFF 120.85%);
    position: relative;
    z-index: 1;
    transition: .2s linear;
    &::before {
        position: absolute;
        content: "";
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        border-radius: inherit;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: hsl(var(--white));
        z-index: -1;
    }
    &__icon {   
        width: 88px;
        height: 88px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: hsl(var(--section-bg));
    }
    &__title {
        margin-bottom: 0;
        margin-top: 24px;
    }
}
.custom-img {
    width: 100%;
    height: auto;
    max-width: 500px; /* Adjust the max-width as needed */
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
  
/* ======================== popular Section Css End =========================== */